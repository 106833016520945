<template>
  <Box>
    
    <ErrorRow :error="error" />
    <SuccessRow :message="message" />
    <SessionTimeoutDialog v-if="showSessionTimeoutDialog" />
        
    <TitleRow>Title</TitleRow>
    <Row>
      <Column>
        <Button>Button </Button>
        <Value> Value </Value>
      </Column>
    </Row>
    
    <LoadingRow :showBorder="true" v-if="isLoading">{{loadingMessage}}</LoadingRow>
    <SpacerRow />

  </Box>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

//import ConstUtils   from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import ConnectionUtils from '@/utils/ConnectionUtils.js';
import StringUtils  from '@/utils/StringUtils.js';

import ErrorRow    from '@/components/row/ErrorRow.vue';
import SuccessRow  from "@/components/row/SuccessRow.vue";

import MC from "@/domain/session/MC.js";

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

import SpacerRow from "@/portals/shared/library/spacer/SpacerRow.vue";
import Button from "@/portals/shared/library/button/Button.vue";
import Value from "@/portals/shared/library/value/Value.vue";
import TitleRow from "@/portals/shared/library/title/TitleRow.vue";
import LoadingRow from "@/portals/shared/library/loading/LoadingRow.vue";  

import ScheduleMap from "@/domain/model/schedule/ScheduleMap.js";
import SessionTimeoutDialog from "@/portals/shared/views/session/SessionTimeoutDialog.vue";

export default {
  name: 'portal-customer-operator-schedule-edit',
  components: {
    Button,
    ErrorRow,
    SuccessRow,
    SpacerRow,
    Value,
    Box, Row, Column,
    TitleRow, LoadingRow, SessionTimeoutDialog,
  },
  props: {
    isAdmin: { type: Boolean, default: false },
  },
  data() {
    return {
      error: null,
      message: null,
      
      isLoading: false,
      loadingMessage: "",

      MC: new MC(),
      
      total: 0,
      page: 0,
      pageSize: 10,
      pageNumber: 0,
      showSessionTimeoutDialog: false,
      
      stateFilter: 'all',
      schedules: [],
    };
  },
  computed: {
    ...mapGetters([
      'auth_connected',
      'auth_socket_status',
      'signin_event',
      'auth_client',
      'domain',
      //
    ]),
    company: function () {
      return this.domain.session().company();
    },
  },
  watch: {
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    }
  },
  mounted: function () {
    ConnectionUtils.ensureConnection(this);
  },
  methods: {
    ...mapActions([
                   'AuthStorage_updateCompany',
                   'addListenerAction',
                   'removeListenerAction',
                   'updateDomain',
                   ]),

    start: function() {
      this.isLoading = false;
      this.message = null;
      this.loadingMessage = "";
      this.loadData();
    },
    
    callback: function(msg) {
      this.loadingMessage = msg;
    },
    
    isEven: function (position) {
      return StringUtils.isEven(position);
    },
    
    decode: function (value) {
      return ContentUtils.unescape(value);
    },
    
    loadData: function() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      this.error = null;
      this.loadingMessage = "Loading...";
      
//      var event = this.domain.events().schedules().details();
//      event.send(this.loadScheduleDetailsListener);
    },
    
    loadScheduleDetailsListener: function(response) {
      if (response.error()) {
        this.error = response.error();
      } else {
        var payload = response.payload();
        var values = payload.valuesFor(ScheduleMap.MODEL_NAME);
        this.schedules = new ScheduleMap(this.domain, values).list();
      }
      this.isLoading = false;
    },
    
    refresh: function () {
      this.schedules = [];
      this.loadData();
    },
    
  },
  
};
</script>
